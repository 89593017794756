<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div v-if="edit" class="px-5">
      <div class="d-flex align-center temp_title">
        <div v-if="edit">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#052633"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="edit = false"
                icon
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              >
            </template>
            <span>Back</span>
          </v-tooltip>
        </div>
        <div v-if="!edit" class="temp_bullet mr-2">
          <div class="dot"></div>
        </div>
        <h3 class="color_default">Form Input Customer</h3>
      </div>

      <div v-if="!loading && dataSet" class="mt-4">
        <v-card flat outlined class="pa-5">
          <v-data-table
            :headers="headers"
            :items="dataSet.data"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:[`item.label`]="{ item }">
              <div class="d-flex align-center">
                <h4 :style="`color:${item.deleted == 1 ? 'gray' : 'black'}`">
                  {{ item.label }}
                </h4>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex align-center">
                <v-radio-group
                  :disabled="item.deleted == 1 ? true : false"
                  v-model="item.status"
                  hide-details
                  class="ma-0"
                  dense
                  row
                >
                  <v-radio
                    label="Filled"
                    color="#052633"
                    value="show"
                  ></v-radio>
                  <v-radio label="Hide" color="#052633" value="hide"></v-radio>
                </v-radio-group>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :disabled="item.deleted == 1"
                v-if="!item.attribute"
                @click="remove(item.id)"
                small
                depressed
                icon
                class="bg_color1 mr-3"
                dark
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                v-if="item.deleted == 1"
                @click="removeDeleted(item.id)"
                small
                depressed
                icon
                class="bg_color1 mr-3"
                dark
              >
                <v-icon>mdi-restore</v-icon>
              </v-btn>
            </template>
            <template slot="body.append">
              <tr class="red--text">
                <th class="title">
                  <v-text-field
                    v-model="custom"
                    placeholder="Custom"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded"
                    prepend-icon="mdi-close"
                    @click:prepend="custom = ''"
                  ></v-text-field>
                </th>
                <th class="title">
                  <div class="d-flex align-center">
                    <v-radio-group
                      v-model="hide_custom"
                      hide-details
                      class="ma-0"
                      dense
                      row
                    >
                      <v-radio
                        label="Filled"
                        color="#052633"
                        value="show"
                      ></v-radio>
                      <v-radio
                        label="Hide"
                        color="#052633"
                        value="hide"
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      :disabled="custom != '' ? false : true"
                      @click="plus('')"
                      small
                      depressed
                      icon
                      class="bg_color1"
                      dark
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </th>
                <th class="title"></th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <div class="mt-6 d-flex align-center justify-end">
          <v-btn
            class="text-capitalize mr-2"
            width="15%"
            depressed
            outlined
            color="red"
            @click="reset"
          >
            <h4>Reset</h4>
          </v-btn>
          <v-btn
            @click="generateLink"
            width="15%"
            class="text-capitalize"
            depressed
            color="#052633"
          >
            <h4 class="txt_white">Generate Link</h4>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="!edit && dataSet" class="px-5">
      <v-card flat color="#052633" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <div class="full_w pa-5 d-flex align-start flex-column">
              <div class="d-flex align-start">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      color="#052633"
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.go(-1)"
                      icon
                      class="mr-2"
                      ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
                    >
                  </template>
                  <span>Back</span>
                </v-tooltip>
                <div>
                  <h2 class="txt_white">
                    Set Up Pendaftaran anda sudah diterapkan
                  </h2>
                  <h4 class="not_bold txt_white">
                    Set Up pendaftaran ini untuk pengisian form customer anda.
                  </h4>
                  <div class="full_w mt-3" v-if="linkPendaftaran">
                    <h4 class="txt_white">Link Form Pendaftaran</h4>
                    <v-text-field
                      dark
                      readonly
                      :value="linkPendaftaran"
                      placeholder="link form untuk customer"
                      color="#0D47A1"
                      dense
                      hide-details
                      outlined
                      class="rounded"
                      append-icon="mdi-content-copy"
                      @click:append="copyLink(linkPendaftaran)"
                    ></v-text-field>
                  </div>
                  <div class="full_w mt-3" v-if="!linkPendaftaran">
                    <h4 class="txt_white">Link Form Pendaftaran</h4>
                    <v-text-field
                      dark
                      readonly
                      :value="linkPendaftaran"
                      placeholder="Silakan generate link form terlebih dahulu"
                      color="#0D47A1"
                      dense
                      hide-details
                      outlined
                      class="rounded"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center justify-end">
            <img
              src="../../assets/img/setup Registrasi.png"
              height="150px"
              style="object-fit: contain"
            />
          </v-col>
        </v-row>
      </v-card>
      <div v-if="role != 'USER' && role != 'ASSOCIATE'">
        <div class="mt-3 d-flex align-center">
          <bullet title="Form Input Customer" />
          <v-btn @click="edit = true" depressed class="bg_color1 ml-3" dark>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>

        <v-card
          class="mt-3"
          rounded="lg"
          style="overflow: hidden"
          flat
          outlined
        >
          <div
            class="d-flex align-center"
            v-for="(item, n) in dataSet.data"
            :key="n"
          >
            <div
              class="bg_color1 pa-3 txt_white"
              :style="
                n <= dataSet.data.length - 1 && n != 0
                  ? 'margin-top: 1px; width: 40%'
                  : 'width: 40%'
              "
            >
              {{ item.label }}
            </div>
            <div
              class="pl-4"
              :style="
                n >= dataSet.data.length - 1
                  ? 'margin-top: 1px; width: 40%'
                  : ''
              "
            >
              {{ item.status == "show" ? "Filled" : "Hide" }}
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="dataSet"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "setupPendaftaran",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      loading: true,
      dataSet: null,
      d_create: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      edit: false,
      linkPendaftaran: "",
      headers: [
        {
          text: "Attribute",
          value: "label",
          width: "50%",
          sortable: false,
        },
        { text: "Option", value: "status", width: "30%", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Set Up Pendaftaran",
          disabled: true,
          href: "#",
        },
      ],
      custom: "",
      hide_custom: "hide",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    copyLink(link) {
      this.$copyText(link)
        .then((data) => {
          this.$store.dispatch("notify", {
            msg: "Copied",
            type: "success",
          });
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: "Can not copy",
            type: "error",
          });
        });
    },
    async fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/registration/custom-form`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.linkPendaftaran = data.link_daftar;
          this.dataSet = data;
          this.loading = false;
          console.log(data);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    remove(id) {
      this.dataSet.data.forEach((item) => {
        if (item.id == id) {
          item.deleted = 1;
        }
      });
    },
    removeDeleted(id) {
      this.dataSet.data.forEach((item) => {
        if (item.id == id) {
          item.deleted = 0;
        }
      });
    },
    plus(idx) {
      this.dataSet.data.push({
        label: this.custom,
        attribute: null,
        status: this.hide_custom,
        validator: "required",
        type: "text",
        deleted: 0,
      });
      this.custom = "";
      this.hide_custom = "hide";
    },
    reset() {
      this.fetchData();
      this.custom = "";
      this.hide_custom = "hide";
    },
    generateLink() {
      this.saveData();
    },
    async saveData() {
      this.loading = true;
      let data = {
        body: {
          custom_form: this.dataSet.data,
        },
        path: `sysadmin/registration/custom-form/save`,
      };
      await this.$store
        .dispatch("client/postApi", data)
        .then(async (data) => {
          await this.fetchData();
          this.d_type = "c_setup_regis";
          this.d_title = "Generate Link";
          this.d_create = true;
          this.line = true;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
  border-bottom: none;
}
</style>
